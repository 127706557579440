<template>

    <v-footer absolute app class="footerpart" inset padless>
        <v-card class="flex" flat tile>
            <!--<v-card-title class="success">
                <strong class="subheading">Get connected with us on social networks!</strong>

                <v-spacer></v-spacer>

                <v-btn v-for="icon in icons"
                       :key="icon"
                       class="mx-4"
                       dark
                       icon>
                    <v-icon size="24px">
                        {{ icon }}
                    </v-icon>
                </v-btn>
            </v-card-title>-->

            <v-card-text class="text--darken-1 text-right">
                {{ new Date().getFullYear() }} — <strong>Iconvu</strong>
            </v-card-text>
        </v-card>
    </v-footer>

</template>
<script>
export default {
        name: "Footer",
        data: () => ({
            icons: [
                'mdi-facebook',
                'mdi-twitter',
                'mdi-linkedin',
                'mdi-instagram',
            ],
        }),
}
</script>
<style lang="scss">
  .v-application .footerpart{
    border-top:1px solid rgba(0,0,0,0.1)!important;
  }
</style>